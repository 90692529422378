import _ from 'lodash'
import { useCallback, useEffect, useMemo } from 'react'
import Callbacks from './callbacks'
import useModelValues from '../../store/useModelValues'
import useUserLanguage from '../user/useUserLanguage'
import HookUtils from '../../../utils/hooks'
const useAddressAutocomplete = ({
  owner,
  coordinates,
  includeRecent = true,
  includeFavorite = true,
  includePOI = true,
  includeExternal = true,
  overrides,
  poiTypes = ['train', 'airport', 'festival'],
  recentTypes = [],
  favoriteTypes = [],
  search_id,
  clearOnUnmount,
  disabled,
  requestOptions,
  watchers = [],
  submitOnUpdate = true
} = {}, extra = {}) => {
  const initial_language = useUserLanguage({ user_id: owner })
  const parseProps = useCallback(
    Callbacks.parsePropsHandler({ includeRecent, includeFavorite, includePOI, includeExternal }, { poiTypes, recentTypes, favoriteTypes, language: initial_language, coordinates }, overrides, extra, requestOptions),
    [includeRecent, includeFavorite, includePOI, includeExternal, poiTypes.join(','), recentTypes.join(','), favoriteTypes.join(','), initial_language, overrides, extra, requestOptions]
  )


  const [addresses, autocomplete, autocomplete_state, { getListener }] = useModelValues('address', 'autocomplete', { q: '', owner, options: parseProps(owner) }, { search_id, clearOnUnmount, watchers, submitOnUpdate })
  const getParams = useCallback(() => getListener()?.getLastArgs() || getListener()?.getInfo(), [autocomplete_state])
  const getParam = useCallback((path) => _.get(getParams(), path), [getParams])
  const isReady = useMemo(() => HookUtils.getReadyState(autocomplete_state) && !_.isEmpty(getParams()), [autocomplete_state, getParams])
  const getAvailableSources = useCallback(() => ['recent', 'favorite', 'poi', 'external'], [])
  const getEnabledSources = useCallback(() => _.map(getParam('options.sources'), 'source'), [getParam])
  const isSourceEnabled = useCallback((source, strict) => _.includes(getEnabledSources(), source) && (!strict || getEnabledSources().length === 1), [getEnabledSources])
  const getSourceTypes = useCallback((source) => _.get(_.find(getParam('options.sources'), ['source', source]), 'type'), [getParam])
  const getSourceOptions = useCallback((source) => _.get(_.find(getParam('options.sources'), ['source', source]), 'options'), [getParam])
  const isSourceTypeEnabled = useCallback((source, type, strict_source, strict_type) =>
    isSourceEnabled(source, strict_source) && _.includes(getSourceTypes(source), type) && (!strict_type || getSourceTypes(source).length === 1)
    , [isSourceEnabled, getSourceTypes])
  const getOwnerID = useCallback(() => getParam('owner'), [getParam])

  const generateSource = useCallback(Callbacks.generateSourceHandler(getSourceTypes, getSourceOptions), [getSourceTypes, getSourceOptions])
  const generateSources = useCallback(Callbacks.generateSourcesHandler(generateSource), [generateSource])

  const reload = useCallback(Callbacks.reloadHandler(autocomplete, getParams), [autocomplete, getParams])
  const reset = useCallback(Callbacks.resetHandler(reload, parseProps, owner), [reload, parseProps, owner])
  const reloadOptions = useCallback(Callbacks.reloadOptionsHandler(reload), [reload])
  const refresh = useCallback(Callbacks.refreshHandler(reload), [reload])
  const onQueryChange = useCallback(Callbacks.onQueryChangeHandler(reload), [reload])
  const enableSources = useCallback(Callbacks.enableSourcesHandler(reloadOptions, generateSources, getEnabledSources), [reloadOptions, generateSources, getEnabledSources])
  const enableSource = useCallback(Callbacks.enableSourceHandler(enableSources), [enableSources])
  const disableSources = useCallback(Callbacks.disableSourcesHandler(reloadOptions, generateSources, getEnabledSources), [reloadOptions, generateSources, getEnabledSources])
  const changeOwner = useCallback(Callbacks.changeOwnerHandler(reload, reset), [reload, reset])

  return [addresses, onQueryChange, _.merge({}, autocomplete_state, { isReady }), {
    getAvailableSources,
    getEnabledSources,
    isSourceEnabled,
    isSourceTypeEnabled,
    getSourceTypes,
    getOwnerID,
    enableSource,
    enableSources,
    disableSources,
    refresh,
    reset,
    changeOwner
  }]
}

export default useAddressAutocomplete
