import _ from "lodash"
class Callbacks {
  static parsePropsHandler({ includeRecent, includeFavorite, includePOI, includeExternal }, { poiTypes, recentTypes, favoriteTypes, language, coordinates }, overrides, extra, requestOptions) {
    return function parseProps(owner) {
      return {
        sources: _.compact([
          !!includeFavorite && !!owner ? { source: 'favorite', type: favoriteTypes, limit: _.get(requestOptions, 'favoriteLimit') } : null,
          !!includeRecent && !!owner ? { source: 'recent', type: recentTypes, limit: _.get(requestOptions, 'recentLimit') } : null,
          !!includePOI ? { source: 'poi', type: poiTypes, options: _.pick(requestOptions, ['commercial_formula', 'schedule_type', 'direction_type']) } : null,
          !!includeExternal ? { source: 'external' } : null
        ]),
        limit: 15,
        external_threshold: 1,
        lang: language,
        coordinates,
        ...overrides,
        ...extra
      }
    }
  }
  static reloadHandler(autocomplete, getParams) {
    return function reload(overrides) {
      const existing_params = _.has(overrides, 'options.sources') ? _.omit(getParams(), ['options.sources']) : getParams()
      return autocomplete(_.merge({ q: '' }, existing_params, overrides))
    }
  }
  static reloadOptionsHandler(reload) {
    return function reloadOptions(option_overrides, extra) {
      return reload(_.merge({ options: option_overrides }, extra))
    }
  }
  static refreshHandler(reload) {
    return function refresh() {
      return reload()
    }
  }
  static generateSourceHandler(getSourceTypes, getSourceOptions) {
    return function generateSource(source, type, options) {
      return { source, type: type || getSourceTypes(source), options: _.merge({}, getSourceOptions(source), options) }
    }
  }
  static generateSourcesHandler(generateSource) {
    return function generateSources(sources, type_overrides, options_overrides) {
      return _.map(sources, (source) => generateSource(source, _.get(type_overrides, source), _.get(options_overrides, source)))
    }
  }
  static onQueryChangeHandler(reload) {
    return function onQueryChange(q) {
      return reload({ q: q || '' })
    }
  }
  static enableSourcesHandler(reloadOptions, generateSources, getEnabledSources) {
    return function enableSources(names, types, options) {
      const { disableEmptyQuery } = options || {}
      const query = !disableEmptyQuery ? { q: '' } : {}
      if (!!_.get(options, 'strict'))
        return reloadOptions({
          sources: generateSources(names, types, _.omit(options, ['strict']))
        }, query)
      return reloadOptions({
        sources: generateSources(_.uniq(_.flatten([getEnabledSources(), names])), types, _.omit(options, ['strict']))
      }, query)
    }
  }
  static enableSourceHandler(enableSources) {
    return function enableSource(name, type, options) {
      return enableSources([name], { [name]: type }, { strict: _.get(options, 'strict'), [name]: _.omit(options, ['strict']) })
    }
  }
  static disableSourcesHandler(reloadOptions, generateSources, getEnabledSources) {
    return function disableSources(names) {
      return reloadOptions({
        sources: generateSources(_.filter(getEnabledSources(), (source_name) => !_.includes(names, source_name)))
      })
    }
  }
  static resetHandler(reload, parseProps, owner) {
    return function reset(overrides) {
      return reload(_.merge({
        options: parseProps(owner || _.get(overrides, 'owner'))
      }, overrides))
    }
  }
  static changeOwnerHandler(reload, reset) {
    return function changeOwner(owner, update_sources) {
      if (!!update_sources)
        return reset({ owner: owner || null })
      return reload({ owner: owner || null })
    }
  }
}

export default Callbacks
